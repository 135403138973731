<template>
	<div>
		<div class='a-trip'>
			<div class="app-container a-trip-container">
				<p class="a-heading-1">{{$t('account.trips.suitcase')}}</p>
				<div class="row a-trip-search">
					<div class="col">
						<v-text-field
							:placeholder="$t('account.trips.placeholder_search')"
							icon-prepend='search'
							name='search'
							v-model='search'
							hide-details
						/>
					</div>
					<div class="col-auto">
						<div class='a-trip-search-button'>
							<v-button xxsmall color='green white--text'
								@click='updateSearch'
								:loading='loading_btn'
							>{{$t('hc.search')}}</v-button>
						</div>
					</div>
				</div>

				<div class="a-trip-preloader" v-if='initLoading'>
					<div>
						<div class='a-trip-preloader-icon'>
							<span v-html="require(`!html-loader!@/assets/baggage.svg`)" />
						</div>
					</div>
				</div>

				<div class='a-trip-content' v-else-if='total > 0'>
					<div class="row align-items-center a-trip-header-row">
						<div class="col">
							<p class='a-trip-header-title'>{{$t('account.trips.total_suitcase', {tour: `${total} ${$helpers.declOfNum(total, $t('account.declOfNum.tours'))}`})}}</p>
						</div>
						<div class="col-auto">
							<div class='a-trip-sorting'>
								<p class='a-trip-sorting-title'>{{$t("account.sort_by")}}:</p>
								<span class='a-trip-sorting-list'>
									<span class='a-trip-sorting-item' :class='{"active": sort === "date_from"}' @click='sort = "date_from", getSuitcase()'>{{$t('account.trips.date_tour')}}</span>
									<span class='a-trip-sorting-item' :class='{"active": sort === "updated_at"}' @click='sort = "updated_at", getSuitcase()'>{{$t('account.trips.date_changes_tour')}}</span>
								</span>
							</div>
						</div>
					</div>

					<a-trip-card
						v-for='(order, i) in orders' :key='i'
						:order='order'
						@open-dialog-change='openDialogChange($event)'
						@updateSuitcase='getSuitcase'
						@removeFromSuitcase='removeFromSuitcase'
					/>

					<v-pagination
						:total='total > 0 ? total : 1'
						:page='page'
						:perPage='perPage'
						@update-pagination='updatePagination'
					/>
				</div>

				<div class="a-trip-preloader" v-else>
					<div>
						<div class='a-trip-preloader-icon'>
							<span v-html="require(`!html-loader!@/assets/baggage.svg`)" />
						</div>
						<p class="a-trip-preloader-title">{{$t('account.trips.suitcase_is_empty')}}</p>
						<p class='a-trip-preloader-text'>{{$t('account.trips.unfortunately_there_are_no_tours_available_in_the_suitcase')}}</p>
					</div>
				</div>
			</div>
		</div>

		<a-dialog-change
			v-model='dialog_change.active'
			:dialog_change='dialog_change'
			@close-dialog="closeDialogChange"
		/>
	</div>
</template>

<script>
	import ATripCard from '@/views/account/trips/card';
	import ADialogChange from '@/views/account/trips/dialogs/change';
	import { getSuitcase, updateInfo } from '@/services/request';

	export default {
		data: () => ({
			search: '',
			orders: [],
			page: 1,
			perPage: 5,
			total: 0,
			meta: {},
			sort: 'updated_at',
			initLoading: true,
			loading: false,
			loading_btn: false,
			dialog_change: {
				key: null,
				orderId: null,
				active: false,
				title: null,
				initValue: null,
				placeholder: null,
				loading_btn: false,
				action: () => {}
			}
		}),
		components: {
			ATripCard,
			ADialogChange
		},
		created() {
			this.getSuitcase();
		},
		methods: {
			getSuitcase() {
				this.loading = true;
				getSuitcase({
					search: this.search,
					page: this.page,
					sort: this.sort
				}).then(res => {
					this.orders = res.data;
					this.page = res.meta.current_page;
					this.perPage = res.meta.per_page;
					this.total = res.meta.total;
					this.meta = res.meta;
					this.$store.commit('suitcase_total', res.meta.total)
				}).finally(() => this.loading = false, this.loading_btn = false, this.initLoading = false)
			},
			updateSearch() {
				this.page = 1;
				this.loading_btn = true;
				this.getSuitcase();
			},
			updatePagination($event) {
				this.page = $event;
				this.getSuitcase();
			},
			openDialogChange({orderId, value, key, mask}) {
				this.dialog_change = {
					key,
					orderId,
					active: true,
					initValue: value,
					loading_btn: false,
					mask,
					action: ($event) => this.updateInfo($event)
				}

				switch(key) {
					case 'name': {
						this.dialog_change.title = this.$t("account.itinerary.content.name_tour");
						this.dialog_change.placeholder = `${this.$t("account.itinerary.content.name_tour")}...`;
						return true;
					}
					case 'client': {
						this.dialog_change.title = this.$t("account.trips.for_whom_it_is_created");
						this.dialog_change.placeholder = `${this.$t("account.trips.name_last")}...`;
						return true;
					}
					case 'phone': {
						this.dialog_change.title = this.$t("account.trips.phone");
						this.dialog_change.placeholder = '+380 (00) 000 00 00';
						return true;
					}
					case 'email': {
						this.dialog_change.title = 'E-mail';
						this.dialog_change.placeholder = 'email@email.com';
						return true;
					}
					case 'mark': {
						this.dialog_change.title = this.$t("account.trips.add_mark");
						this.dialog_change.placeholder = this.$t("account.trips.add_mark_placeholder");
						return true;
					}
					default:
						return false
				}
			},
			updateInfo($event) {
				this.dialog_change.loading_btn = true;
				updateInfo({
					orderId: this.dialog_change.orderId,
					data: { [`${this.dialog_change.key}`]: $event }
				})
					.then((res) => {
						this.orders = this.orders.map(el => {
							if(res.data.id === el.id) {
								return res.data
							} else {
								return el
							}
						});
						this.closeDialogChange();
					})
					.finally(() => this.dialog_change.loading_btn = false)
			},
			removeFromSuitcase() {
				if(((this.meta.current_page - 1) * this.meta.per_page) === this.meta.total - 1 ) {
					this.page = this.page - 1;
				}

				this.getSuitcase();
			},
			closeDialogChange() {
				this.dialog_change = {
					key: null,
					orderId: null,
					active: false,
					title: null,
					initValue: null,
					placeholder: null,
					loading_btn: false,
					action: () => {}
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-trip {
		padding: 35px 0 80px;
		display: grid;
		min-height: calc(100vh - 70px);
		@include minw( $grid-breakpoints-sm ) {
			padding: 80px 0;
			min-height: calc(100vh - 105px * .8);
		}
		@include minw( $grid-breakpoints-md ) {
			min-height: calc(100vh - 105px);
		}
		&-container {
			max-width: 1292px;
		}
		&-content {
			margin-top: 30px;
			@include minw( $grid-breakpoints-xs ) {
				margin-top: 40px;
			}
		}
		.a-heading-1 {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				text-align: center;
			}
		}
		&-search {
			margin-top: 15px;
			@include minw( $grid-breakpoints-xs ) {
				align-items: center;
				margin-top: 30px;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
			.app-input {
				margin-bottom: 0;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-bottom: 15px;
				}
			}
			&-button {
				.app-btn {
					@include minw( $grid-breakpoints-xs ) {
						min-width: 150px !important;
					}
				}
			}
		}
		&-header {
			&-row {
				margin-bottom: 25px;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-bottom: 40px;
					flex-direction: column;
					align-items: flex-start;
				}
			}
			&-title {
				font-size: 16px;
				font-weight: 400;
				@include maxw( $grid-breakpoints-xs - 1 ) {
					font-size: 15px;
					margin-bottom: 20px;
				}
			}
		}
		&-title {
			font-size: 16px;
			font-weight: 400;
		}
		&-sorting {
			@include minw( $grid-breakpoints-xs ) {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
			&-title {
				font-size: 12px;
				font-weight: 400;
				margin: 5px 0;
				@include minw( $grid-breakpoints-xs ) {
					margin-right: 20px;
				}
			}
			&-list {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}
			&-item {
				font-size: 13px;
				font-weight: 400;
				color: rgba($black, .6);
				border: 1px solid rgba($black, .15);
				border-radius: 10px;
				padding: 8px 12px;
				margin: 5px 0;
				cursor: pointer;
				transition: color $transition, border-color $transition;
				&:hover,
				&.active {
					color: $green-persian;
					border-color: $green-persian;
				}
				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
		&-preloader {
			padding: 50px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			@include minw( $grid-breakpoints-xs ) {
				height: calc(100% - 50px * 2);
			}
			&-icon {
				width: 125px;
				height: 132px;
				margin: auto;
				margin-bottom: 30px;
				@include minw( $grid-breakpoints-xs ) {
					width: 201px;
					height: 213px;
					margin-bottom: 40px;
				}
			}
			&-title {
				font-size: 25px;
				font-weight: 700;
				margin-bottom: 16px;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 20px;
				}
			}
			&-text {
				font-size: 14px;
				font-weight: 400;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 16px;
				}
			}
		}
	}
</style>
